import React from 'react';
import get from 'lodash.get';
import Helmet from 'react-helmet';
import removeMd from 'remove-markdown';
import { getMetadata } from '../helpers';

import {
  getDataExhibition,
} from '../proxies';

import {
  Exhibition,
  LayoutContextConsumer,
  Overlay,
} from '../components';

const ContentExhibition = (props = {}) => {
  const selectedItem = getDataExhibition(get(props, 'pageContext.selected', {}));
  return (
    <LayoutContextConsumer>
      <Helmet
        title={get(selectedItem, 'title', '')}
        meta={getMetadata({
          metaDescription: {
            metaDescription: removeMd(get(selectedItem, 'copy', '').split(' ').slice(0, 25).join(' ')),
          },
          metaTitle: get(selectedItem, 'title', ''),
          metaType: 'Article',
          ogImage: {
            file: {
              url: get(selectedItem, 'imagesSlider.0.src', ''),
            },
          },
        })}
      />
      <Overlay brown>
        <Exhibition content={selectedItem} />
      </Overlay>
    </LayoutContextConsumer>
  );
};

export default ContentExhibition;
